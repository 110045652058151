import React, { useEffect } from "react"
import logo from './logo.svg';
import './App.css';
import myData from './epessentials-courses.json';
import myCategories from './categories.json';

function Iframe(props) {     
   return(         
      <div>          
        <iframe title={props.src} src={props.src} height={props.height} width={props.width}/>         
      </div>
    )
  };

function App() {

  useEffect(() => {
    const epcourses_url = "https://epessentials.talentlms.com/api/v1/courses/";
    const epcourse_url = "https://epessentials.talentlms.com/api/v1/courses/id:";
    var headers = new Headers();
    headers.append('Authorization', 'Basic RGREVXI0SmdsbURGM2NnbHR1eVJEMU1tcmR1WWtUOg==');
    //fetch('https://epessentials.talentlms.com/api/v1/courses/', {headers: headers})

    let courses = [];

    const fetchData = async () => {
      try {
        const response = await fetch(epcourses_url, {headers: headers});
        const json = await response.json();
        //console.log(json);

        for (let obj of json) {
          setTimeout(() => {  console.log("World!"); }, 200000);
          const response2 = await fetch(epcourse_url + obj.id, {headers: headers});
          const course_json = await response2.json();
          delete course_json['users'];
          // console.log(obj.id, course_json);
          obj['course'] = course_json;
          courses.push(obj);
      }

      console.log(courses);

      } catch (error) {
        console.log("error", error);
      }
    };

    if (myData.length === 0) {
      console.log("Fetching DATA");
      fetchData();
    }
       
 
    for (let obj of myData) {
        console.log(obj);
      }
    
    

  }, []);

  return (
    <div className="App">
      <div>
      {myCategories.sort(function(a, b){
    return a.id - b.id;
}).map(cat => { return (<div>
        <h3>{cat.id} {cat.name}</h3>
      { myData.filter(  c=> c.category_id === cat.id ).sort(function(a, b){
    return a.id > b.id;
}).map(course=>{
        return (
        <div key={course.id} > <div key={course.id}> <h4><img src={course.course.avatar} alt={course.course.name} /> {course.course.name}</h4></div>
        {course.course.units.map(unit=>{
          return (<div>   <a href={unit.url} target="viewer">{unit.name} ({unit.type})</a></div>)
        })}
        </div>
        )
      })}
      </div>)
    })}

</div>
<div>

</div>
    </div>

  );
}

export default App;
